<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button class="ml-4" />
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loaders.submit">
        <div class="mt-1 row px-3 mx-0 manage-users-edit">
          <div class="col-12 mb-4 d-flex justify-content-between">
            <h1>{{ `Edit User Info` }}</h1>
          </div>
          <div class="col-12">
            <card class="p-2" v-loading="loaders.fetching">
              <h2 class="mb-4 heading">Basic Info</h2>
              <div class="row">
                <div class="col-6">
                  <base-input label="First Name*" placeholder="First Name" :rules="{ custom_required: true }"
                    v-model="form.first_name" name="first_name"></base-input>
                </div>
                <div class="col-6">
                  <base-input label="Last Name*" placeholder="Last Name" :rules="{ custom_required: true }"
                    v-model="form.last_name" name="last_name"></base-input>
                </div>
                <div class="col-6">
                  <base-input label="Email*" placeholder="Email" :rules="{ custom_required: true }" v-model="form.email"
                    name="email"></base-input>
                </div>
                <div class="col-6">
                  <base-input label="Phone" v-mask="{mask:'999-999-9999'}" placeholder="123-456-7890" :rules="{ custom_required: false }" v-model="form.phone"
                    name="phone"></base-input>
                </div>
                <div class="col-6">
                  <label class="form-control-label">Height*</label>
                  <div class="row">
                    <div class="col-6">
                      <base-select :rules="{ custom_required: true }" :options="dropdowns.feet" v-model="form.feet"
                        name="feet"></base-select>
                    </div>
                    <div class="col-6">
                      <base-select :rules="{ custom_required: true }" :options="dropdowns.inches" v-model="form.inches"
                        name="inches"></base-select>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <validation-provider :rules="{ required: true }" name="dob"
                    v-slot="{ errors, valid, invalid, validated, validate }">
                    <label class="form-control-label">DOB*</label>
                    <custom-date-picker :inputData.sync="form.dob" v-model="form.dob"
                      :maxDate="new Date()" :show-placeholder="''" :show-image-icon="false"
                      :class="{ 'is-invalid': validated && invalid }">
                    </custom-date-picker>
                    <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="col-6">
                  <base-select label="Status*" :options="dropdowns.status" :rules="{ custom_required: true }"
                    v-model="form.status" name="status"></base-select>
                </div>
                <div class="col-6">
                  <base-input label="MRN Number (Optional)" :rules="{ custom_required: false }" v-model="form.mrn_number"
                    name="mrn_number"></base-input>
                </div>
              </div>
            </card>
          </div>
          <div class="col-12">
            <card class="p-2" v-loading="loaders.fetching">
              <h2 class="mb-4 heading">Surgery</h2>
              <div class="row">
                <div class="col-6">
                  <base-select label="Initial Stage*" :options="dropdowns.stages" :rules="{ custom_required: true }"
                    v-model="form.inital_stage" name="inital_stage"></base-select>
                </div>
                <div class="col-6">
                  <base-select label="Current Stage*" :options="dropdowns.stages" :rules="{ custom_required: true }"
                    v-model="form.current_stage" name="current_stage"
                    @change="surgeryInfoChanged('current_stage')"></base-select>
                </div>
                <div class="col-6">
                  <validation-provider :rules="{ required: false }" name="surgery_date"
                    v-slot="{ errors, valid, invalid, validated, validate }">
                    <label class="form-control-label">Surgery Date</label>
                    <custom-date-picker :inputData.sync="form.surgery_date"
                      @change="(value) => { surgeryInfoChanged('surgery_date'); validate(value) }" :show-placeholder="''"
                      :show-image-icon="false" :class="{ 'is-invalid': validated && invalid }">
                    </custom-date-picker>
                    <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div class="col-6">
                  <base-select label="Type Of Surgery" :options="dropdowns.types_of_surgery"
                    :rules="{ custom_required: false }" v-model="form.type_of_surgery"
                    name="type_of_surgery"></base-select>
                </div>
              </div>
            </card>
          </div>
          <div class="col-12">
            <card class="p-2" v-loading="loaders.fetching">
              <h2 class="mb-4 heading">Weight</h2>
              <div class="row">
                <div class="col-6">
                  <base-input label="Starting Weight (LBS)" :rules="{ custom_required: false }"
                    v-model="form.starting_weight" type="number" step="any" name="starting_weight"></base-input>
                </div>
                <div class="col-6">
                  <base-input label="Current Weight (LBS)" :rules="{ custom_required: false }"
                    v-model="form.current_weight" type="number" step="any" name="current_weight"></base-input>
                </div>
                <div class="col-6">
                  <base-input label="Target Weight (LBS)" type="number" step="any" :rules="{ custom_required: false }" v-model="form.target_weight"
                    name="target_weight"></base-input>
                </div>
              </div>
            </card>
          </div>
          <div class="col-12 mb-5">
            <base-button type="dark-blue" native-type="submit" size="xl">Update</base-button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import patientConstants from "@/constants/patientConstants";
import BackButton from "@/components/Router/BackButton";
import CustomDatePicker from "@/views/Components/CustomDatePicker";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import moment from "moment";

export default {
  components: {
    BackButton,
    CustomDatePicker
  },
  mixins: [generateErrorMessageMixin],
  data() {
    return {
      loaders: {
        fetching: false,
        submit: false,
      },
      user: {},
      stages: {
        25: 'Considering Surgery',
        50: 'Pre-Op',
        75: 'Post-Op',
        100: 'Post-Op',
        125: 'Post-Op',
        150: 'Non-Surgical',
      },
      dropdowns: {
        status: patientConstants.status,
        feet: patientConstants.feet,
        inches: patientConstants.inches,
        stages: patientConstants.stages,
        types_of_surgery: patientConstants.types_of_surgery,
      },
      form: {
        ...this.$store.getters['ManageUsersModule/getPatient']
      }
    };
  },
  watch: {
    'form.feet': function (newValue) {
      this.form.hight = this.form.feet + "." + this.form.inches;
    },
    'form.inches': function (newValue) {
      this.form.hight = this.form.feet + "." + this.form.inches;
    },
    user: function (newValue) {
      this.form.first_name = newValue.first_name;
      this.form.last_name = newValue.last_name;
      this.form.email = newValue.email;
      this.form.phone = newValue.phone;
      this.form.dob = newValue.dob;
      this.form.status = newValue.status;
      this.form.mrn_number = newValue.mrn_number;
      this.form.inital_stage = newValue.inital_stage;
      this.form.current_stage = newValue.current_stage;
      this.form.surgery_date = newValue.surgery_date;
      this.form.type_of_surgery = newValue.type_of_surgery;
      this.form.starting_weight = newValue.starting_weight;
      this.form.current_weight = newValue.current_weight;
      this.form.target_weight = newValue.target_weight;
      this.setHeight(newValue.hight);
    }
  },
  mounted() {
    this.getManageUsersById();
  },
  methods: {
    /**
     *  Function is used to set height into feet and inches
     *  
     *  @param height
     * 
     *  @return void
     */
    setHeight(height) {
      let heightTemp = height.split(".");
      this.form.feet = heightTemp[0] || "";
      this.form.inches = heightTemp[1] || "";
      this.form.hight = height;
    },

    /**
     *  Function is used to toast error message 
     *  
     *  @param field
     *  @param stage
     *  @param message
     * 
     *  @return void
     */
    notifyError(field, stage, message) {
      this.$notify.error({
        title: 'Patient Current Stage',
        message: message
      })
      this.form[field] = stage
    },

    /**
     *  Function is used to toast error message according to field param  
     *  
     *  @param field
     * 
     *  @return void
     */
    surgeryInfoChanged(field) {
      let vm = this
      if (field === 'surgery_date') {
        if (vm.form.surgery_date && moment(vm.form.surgery_date) < moment() && (vm.form['current_stage'] == '25' || vm.form['current_stage'] == '50')) {
          vm.form['current_stage'] = '75'
          return
        } else if (vm.form.surgery_date && moment(vm.form.surgery_date) > moment() && vm.form['current_stage'] == '75') {
          vm.form['current_stage'] = '50'
          return
        }
      } else if (field === 'current_stage') {
        if (vm.form.surgery_date && moment(vm.form.surgery_date) < moment() && (vm.form[field] == '25' || vm.form[field] == '50')) {
          vm.notifyError(field, '75', 'Your surgery date is already passed. Change your surgery date to the future if you\'d like to be pre-op.')
          return
        } else if (vm.form.surgery_date && moment(vm.form.surgery_date) > moment() && vm.form[field] == '75') {
          vm.notifyError(field, '50', 'Your surgery date is in the future. Please change your surgery date to the past if you\'d like to be post-op.')
          return
        }
      }
    },

    /**
     *  Function is used to fetch patient by id  
     *  
     *  @return void
     */
    async getManageUsersById() {
      this.loaders.fetching = true;
      let id = this.$route.params.id;
      try {
        let response = await this.$store.dispatch('ManageUsersModule/_getManageUsersById', { id });

        let {
          data: { data },
        } = response;
        this.user = data;
        this.user.surgery_date = data.surgery_date ? moment(data.surgery_date).format("MM/DD/YYYY") : '';
      } catch (error) {
        if (error?.response?.status == 404) {
          this.$router.push({ name: 'admin.manage-users.index' });
        }

      }
      this.loaders.fetching = false;
    },

    /**
     *  Function is used to update patient by id  
     *  
     *  @return void
     */
    async onSubmit() {
      let validation = this.$refs.formValidator;
      this.loaders.submit = true;
      let id = this.$route.params.id;
      try {
        let response = await this.$store.dispatch('ManageUsersModule/_updateManageUsersById', { fd: this.form, id });
        let {
          data: { data,message },
        } = response;
        this.$notify.success({
            title: 'Manage Users',
            dangerouslyUseHTMLString: true,
            message: message
        });
        this.$router.push({name:'admin.manage-users.index'})
      } catch (error) {
        this.showErrorOnForm(error, 'Manage user', validation)

      }
      this.loaders.submit = false;
    },

  },
};
</script>
<style lang="scss"></style>  